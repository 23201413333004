import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { administrationLabel } from "../../components/newcustomer/labels";
import useSettings from "../../../../../hooks";
import clientService from "../../../../../services/clientservice";
import AutocompleteClientDropDown from "../../../../../hooks/useClientList";
import { extraValues } from "../utils";
import { useTranslation } from "react-i18next";
registerLocale("fr", fr);

function AddActivity({  setAddCustomer, id, activityclient }) {
  const { empemail, activity } = useSettings();
  const { register, setValue, control, formState, setError, handleSubmit } =
    useForm();
  const [clientlist, setClientList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const navigate = useNavigate();
  const { activity_id } = useParams();
  const [clientIdData, setClientIdData] = React.useState(null);
  const [patchclientId, setPatchClientId] = React.useState(null);

  
  const onSubmit = async (data) =>  
  {    setLoader(true);
    data.client_id = clientIdData?.client_id || patchclientId || id;
    
    const activities = await extraValues(data.activity, "ACTIVITY");
    data.activitylist = activities;

    data.date_of_activity = new Date(data.date_of_activity).getTime();
  if(activity_id || activityclient){
clientService?.updateActivity(activity_id || activityclient, data).then((res) => {
    if (res) {
      setLoader(false);
      toast.success("Activity ajoutée avec succès");
      setAddCustomer(false);
    }
  })
  .catch((error) => {
    setLoader(false);
    toast.error(Object.values(error?.msg[0]));
  });

  }
  else{
    clientService
        ?.addActivity(data)
        .then((res) => {
          if (res) {
            setLoader(false);
            toast.success("Activity ajoutée avec succès");
            setAddCustomer(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(Object.values(error?.msg[0]));
        });
    
  }
  
    
   };
  React.useEffect(() => {
    if (id && clientlist?.length)
      setValue("client_id", clientlist.filter((x) => x.value == id)[0]?.label);
  }, [id, clientlist]);

  React.useEffect(() => {
    clientService
      .getClientInfo()
      .then((client) => {
        // setPatchClientId(res.client_id || id);
        const clientlist = client?.map((e) => {
          return {
            label: `${e.surname} ${e.client_name}`,
            value: e?.client_id,
          };
        });
        setClientList(clientlist);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const getAdmindetails = (data, defaultdata) => {
    const recordsplit = data?.length ? data?.split(","):[] ;
    const arr = [];
    recordsplit.map((parter) => {
      const matchingItem = defaultdata.find((item) => item?.value === parter);

      if (matchingItem) {
        arr.push(matchingItem);
      }
    });
    return arr;
  };
  React.useEffect(() => {
    if (activity_id || activityclient) {
      clientService
        .getActivityById(activity_id || activityclient)
        .then((res) => {
          if (res) {
            setPatchClientId(res.client_id || id);
            const patchclient =
              clientlist?.length &&
              clientlist
                ?.filter((x) => x?.value == res?.client_id)
                ?.map((x) => {
                  return {
                    label: x?.label,
                    value: x?.value,
                  };
                });
            if (activityclient) {
              setValue("client_id", patchclient[0]?.label);
            } else {
              setValue("client_id", patchclient[0]);
            }  const patchactivity = getAdmindetails(res?.activitylist, activity);

            setValue("activity", patchactivity);
            setValue(
              "date_of_activity",
              res?.date_of_activity && res?.date_of_activity
            );
            setValue("feedback", res?.feedback);
          }
        });
    }
  }, [activity_id, clientlist, empemail]);

  const storeClientKeyHandler = (client) => {
    setClientIdData(client);
  };
  const { t } = useTranslation("global");

  return (
    <div className="mt-4">
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              
                <Form.Group className="mb-3" controlId="Role">
                  <Form.Label className="text-secondary fw-bold">
                    {administrationLabel?.client} *
                  </Form.Label>
                  {/* <Controller
                    name="client_id"
                    control={control}
                    rules={{ required: "Ce champ est obligatoire" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={(date) => {
                          field.onChange(date);
                          if (formState.errors["client_id"]) {
                            setError("client_id", {
                              type: "manual",
                              message: "",
                            });
                          }
                        }}
                        options={clientlist}
                        placeholder="Sélectionner"
                      />
                    )}
                  />
                  {formState.errors.client_id && (
                    <p className="text-danger">
                      {formState.errors.client_id.message}
                    </p>
                  )} */}
                  <AutocompleteClientDropDown
                    storeClientKeyHandler={storeClientKeyHandler}
                    patchClientId={patchclientId || id || null}
                  />

                </Form.Group>
              
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <label className="form-label text-secondary fw-bold">
              {t("Activités")}
              </label>
              <Controller
                name="activity"
                control={control}
                rules={{ required: t("Ce champ est obligatoire") }}

                render={({ field }) => (
                  <CreatableSelect
                    {...field}
                    isMulti
                    onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["activity"]) {
                          setError("activity", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                    formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
                    options={activity}
                    placeholder={t("Sélectionner")}
                  />
                )}
              />
              {formState.errors.activity && (
                    <p className="text-danger">
                      {formState.errors.activity.message}
                    </p>
                  )}
               
            </Col>

            <Col md={6}>
              <label className="form-label text-secondary fw-bold">
                {t("Date d’activité")}
              </label>

              <Col md={6}>
                <Controller
                  name="date_of_activity"
                  control={control}
                  rules={{ required: t("Ce champ est obligatoire") }}

                  render={({ field }) => (
                    <DatePicker
                      locale="fr"
                      selected={field.value}
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["date_of_activity"]) {
                          setError("date_of_activity", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      className="form-control date_picker placeholder-color"
                      placeholderText="AAAA/MM/JJ"
                      dateFormat="yyyy/MM/dd"
                      wrapperClassName="datePicker"
                    />
                  )}
                />
                  {formState.errors.date_of_activity && (
                  <p className="text-danger">
                    {formState.errors.date_of_activity.message}
                  </p>
                )}
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group className="" controlId="lastname">
                <Form.Label className="text-secondary fw-bold">
                  {t("Commentaires")} *
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  {...register("feedback", {
                    required: t("Ce champ est obligatoire"),
                  })}
                />
                {formState?.errors?.feedback && (
                  <p className="text-danger">
                    {formState?.errors?.feedback?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="col-md-3">
            <button
              style={{ width: "120px" }}
              className="btn btn-primary btn-sm"
              type="submit"
              disabled={loader}
            >
              {loader && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              {t(activity_id ? "Mise à jour" : "Sauvegarder")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddActivity;
