import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import warning from "../../../../../../asset/warning1.png"

const DeleteAlert = ({ showdelete, onCancelDelete, handleDeleteConfirm }) => {

    return (
        <Modal show={showdelete} onHide={() => onCancelDelete(false)}>
            <Modal.Body>
                <div className='text-center' >
                    <Row>
                        <Col md={12}>
                            <img src={warning} width="120px" height="120px" />
                        </Col>

                    </Row>
                    <Row className='text-center my-3'>
                        <Col md={12}>
                            <h3>Vous ne pourrez pas revenir en arriére!</h3>
                        </Col>
                    </Row>

                    <Row className='text-center mt-4'>
                        <Col md={4}></Col>
                        <Col md={2} className='me-2'>
                            <Button
                                variant="secondary"
                                onClick={() => onCancelDelete(false)}
                            >
                                Annuler
                            </Button>
                        </Col>
                        <Col md={2}>
                            <Button
                                variant="danger"
                                onClick={() => {
                                    handleDeleteConfirm()
                                }}
                            >
                                Oui
                            </Button>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteAlert;