import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Container, Form, Col } from "react-bootstrap";
import { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import DatePicker from "react-datepicker";
import TimePicker from "react-bootstrap-time-picker";
import { ClientApppointmentLabel } from "../../labels";
import useSettings from "../../../../../../../hooks";
import appointmentservice from "../../../../../../../services/appointmentservice";
import { toast } from "react-hot-toast";
import AutocompleteClientDropDown from "../../../../../../../hooks/useClientList";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../LanguageSelector";

export default function AppointmentCalendarForm({ data, id, onHide }) {
  const { empemail } = useSettings();
  const [loader, setLoader] = React.useState(false);
  const [clientIdData, setClientIdData] = React.useState(null);
  const [email, setEmail] = React.useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [serverError, setServerError] = React.useState(false);


  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: {
      email_field: [{ key: "", value: "" }],
    },
  });
 

  const { fields, append, remove } = useFieldArray({
    control,
    name: "email_field",
  }); 

  const meeting_persons = watch("meeting_person");

  React.useEffect(() => {
    if (meeting_persons?.length === 0) {
      append({ key: "", value: "" });
    }
  }, [meeting_persons]);

  const meet_mode = [
    { value: "1", label: "En ligne" },
    { value: "2", label: "En personne" },
  ];

  const formData = async (formInfo) => {
    setLoader(true);
    const appoint = [];

    formInfo?.email_field.map((x, i) => {
      appoint.push({
        end_time: x.end_time,
        start_date_and_time: x.start_date_and_time,
        start_time: x.start_time,
        employee_id: x.meeting_emp.value,
        organizer: x.organizer?.value,
        email: formInfo.email_person[i].value,
      });
    });

    const param = {
      client_id: clientIdData?.client_id,
      email: watch("email"),
      meeting_place: formInfo.meeting_place,
      add_info: formInfo.add_info,
      meeting_mode: formInfo.meeting_mode.value,
      employees: appoint,
    };
    appointmentservice
      .createAppointment(param)
      .then((res) => {
        if (res) {
          setLoader(false);
          toast.success("Rendez-vous créé avec succès");
          onHide(false);
          reset();
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response) {
          if (error.response.status === 404) {
            toast.error("Désolé, le planning est déjà pris !");
            setServerError(true);
          }
        }
      });

    return;
  };

  const storeClientKeyHandler = (client) => {
    setClientIdData(client);
    setEmail(client.email == null ? "" : client.email);
  };
  const clientChangehandler = (e) => {
    if (e === "") {
      setClientIdData(null);
    }
  };
  const { t } = useTranslation('global');
  // inside calender client add form
  return (
    <>
    
      <Container className="my-1">
        <Form onSubmit={handleSubmit(formData)}>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>{t("client_name")}</Form.Label>
              <AutocompleteClientDropDown
                storeClientKeyHandler={storeClientKeyHandler}
                onChangeinput={(e) => clientChangehandler(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmpl">
              <Form.Control
                type="email"
                defaultValue={email}
                {...register(`email`)}
                placeholder="Courriel"
                className="placeholder-color"
              />
            </Form.Group>
            {/* // inside calender client add form */}

            <Form.Group className="mb-3" controlId="formBasicaddinfo">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control type="text" {...register("add_info")} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmpl">
              <Form.Label>{t("Meeting place")}</Form.Label>
              <Form.Control
                type="text"
                {...register("meeting_place", {
                  required: t("La date est requise"),
                })}
              />
              {errors.meeting_place && (
                <Form.Text className="text-danger">
                  {errors.meeting_place.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label> {t("Meeting mode")}</Form.Label>{" "}
              <Controller
                control={control}
                name="meeting_mode"
                rules={{ required: t("Le client est requis") }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    onBlur={field.onBlur}
                    placeholder={t("Sélectionner")}
                    options={meet_mode}
                  />
                )}
              />
              {errors.meeting_mode && (
                <span role="alert" className="text-danger">
                  {errors.meeting_mode.message}
                </span>
              )}
            </Form.Group>
          </div>
          <div>
            <label className="form-label">
              {t("No one at the meeting")}
              <MdAdd
                className=" ms-2"
                size={20}
                onClick={() => append({ key: "", value: "" })}
                Option
              />
                {/* // inside calender client add form */}

            </label>

            {fields.map((item, index) => {
              return (
                <>
                  <div className="card mb-3" key={index}>
                    <div className="card-body bg-light">
                      <div className="row mb-2" key={item.id}>
                        <div className="row">
                          <div className="d-flex justify-content-end">
                            <FaRegTrashAlt
                              className="text-danger ms-1"
                              size={15}
                              onClick={() => remove(index)}
                              Remove
                            />
                          </div>
                        </div>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="lastname">
                            <Form.Label>
                              {t("start_date")} *
                            </Form.Label>

                            <Controller
                              name={`start_date_and_time[${index}].key`}
                              control={control}
                              {...register(
                                `email_field.${index}.start_date_and_time`,
                                {
                                  required:
                                    "Confirmar contraseña es obligatorio",
                                }
                              )}
                                // inside calender client add form

                              render={({ field }) => (
                                <>
                                  <DatePicker
                                    locale="fr"
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                    }}
                                    className="form-control placeholder-color"
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="AAAA/MM/JJ"
                                    wrapperClassName="datePicker"
                                    minDate={new Date()}
                                  />
                                  {errors?.email_field?.[index]
                                    ?.start_date_and_time && (
                                      <p className="text-danger">
                                        {t("La date est requise")}
                                      </p>
                                    )}
                                  {index === fields.length - 1 &&
                                    errorMessage && <span>{errorMessage}</span>}
                                </>
                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              {t("organizer")} *
                            </Form.Label>
                            <Controller
                              control={control}
                              {...register(`email_field.${index}.organizer`)}
                              rules={{ required: "Un organisateur est requis" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  defaultValue={item.value}
                                  onChange={(date) => {
                                    field.onChange(date);
                                  }}
                                  onBlur={field.onBlur}
                                  placeholder={t("Sélectionner")}
                                  options={empemail}
                                />
                              )}
                                // inside calender client add form

                            />
                            {errors?.email_field?.[index]?.organizer && (
                              <span role="alert" className="text-danger">
                                {t("Un organisateur est requis")}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="schedule_date"
                          >
                            <Controller
                              control={control}
                              {...register(`email_field.${index}.meeting_emp`)}
                              render={({ field }) => (
                                <>
                                  <CreatableSelect
                                    placeholder={t("Sélectionner")}
                                    {...field}
                                    options={empemail}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      setValue(`email_person[${index}].key`, e);
                                      setValue(
                                        `email_person[${index}].value`,
                                        e.email
                                      );
                                    }}
                                  />
                                  {errors?.email_field?.[index]
                                    ?.meeting_emp && (
                                      <p className="text-danger">
                                        {
                                          errors?.email_field?.[index]
                                            ?.meeting_emp.message
                                        }
                                      </p>
                                    )}
                                </>
                                  // inside calender client add form

                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Control
                            type="email"
                            {...register(`email_person[${index}].value`, {
                              required: "L'e-mail est requis",
                            })}
                            placeholder="Courriel"
                            className="placeholder-color"
                          />
                          {errors?.email_field?.[index]?.email_person && (
                            <p className="text-danger">{t("L'e-mail est requis")}</p>
                          )}
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="client_country"
                          >
                            <Form.Label>
                              {t("start_time")} *
                            </Form.Label>
                            <Controller
                              control={control}
                              name={`email_field.${index}.start_time`}
                              rules={{
                                required: t("L'heure de début est requise"),
                                validate: (value) => {
                                  const startTime = value;
                                  const endTime = watch(`email_field.${index}.end_time`);

                                  if (startTime && endTime) {
                                    if (endTime <= startTime) {
                                      return t("L'heure de fin doit être postérieure à l'heure de début");
                                    }
                                  }
// inside calender client add form 
                                  return true; // Validation passes if there are no errors
                                },
                              }}
                              render={({ field }) => (
                                <>
                                  <TimePicker {...field} format={24} step={30} />
                                  {errors?.email_field?.[index]?.start_time && (
                                    <p className="text-danger">{errors.email_field[index].start_time.message}</p>
                                  )}
                                </>
                                // inside calender client add form 
                                
                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="client_country"
                          >
                            <Form.Label>
                              {t("end_time")} *
                            </Form.Label>
                            <Controller
                              control={control}
                              name={`email_field.${index}.end_time`}
                              rules={{
                                required: t("L'heure de fin est requise"),
                                validate: (value) => {
                                  const startTime = watch(`email_field.${index}.start_time`);
                                  const endTime = value;

                                  if (startTime && endTime) {
                                    if (endTime <= startTime) {
                                      return t("L'heure de fin doit être postérieure à l'heure de début");
                                    }
                                  }

                                  return true; // Validation passes if there are no errors
                                },
                              }}
                              render={({ field }) => (
                                <>
                                  <TimePicker {...field} format={24} step={30} />
                                  {errors?.email_field?.[index]?.end_time && (
                                    <p className="text-danger">{errors.email_field[index].end_time.message}</p>
                                  )}
                                </>
                              )}
                            />
                          </Form.Group>
                        </Col>
                      </div>
                    </div>
                  </div>
                  {serverError && (
                    <div className="text-danger mb-2 text-center">
                      Ce rendez-vous est un conflit avec un autre rendez-vous de votre calendrier
                   s </div>
                  )}
{/* // inside calender client add form  */}
                </>
              );
            })}
          </div>

          <div className="col-md-3">
            <div className="col-md-3">
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                style={{ width: "120px" }}
                disabled={loader}
              >
                {loader && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                {t("send")}  
              </button>
              {/* // inside calender client add form  */}
            </div>
          </div>
        </Form>
      </Container>
    </>
  );
}
