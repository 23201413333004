const typeOfOption = [
  { label: "Membre", value: "membre" },
  { label: "Bénévole", value: "bénévole" },
  { label: "Client", value: "client" },
];

  
const preferred_language = [
  { label: "Français", value: "Français" },
  { label: "Anglais", value: "Anglais" },
  { label: "Autre", value: "Autre" },
];
const listofGender = [
  {
    value: "Femme",
    label: "Femme",
  },
  {
    value: "Homme",
    label: "Homme",
  },
  {
    value: "Autre",
    label: "Autre",
  },
];
const martial_status =[

  {
      value: "Célibataire",
      label: "Célibataire",
    },
    {
      value: "Mariée",
      label: "Mariée",
    },
    {
      value: "Veuve",
      label: "Veuve",
    },
    {
      value: "Divorcée",
      label: "Divorcée",
    },
    {
      value: "Séparée",
      label: "Séparée",
    },
    {
      value: "Union de fait",
      label: "Union de fait",
    },
    {
      value: "Autre",
      label: "Autre",
    },
]

const frequency_services=[
  {
    value: "Session unique",
    label: "Session unique",
  },
  {
    value: "Hebdomadaire",
    label: "Hebdomadaire",
  },
  {
    value: "Mensuelle",
    label: "Mensuelle",
  },
  {
    value: "Autre",
    label: "Autre",
  },
]

export {frequency_services,listofGender,preferred_language , typeOfOption ,martial_status}