import React from "react";
import clientService from "../../../../../services/clientservice";
import Table from "react-bootstrap/Table";
import Loader from "../../../../../utils/Loader";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-hot-toast";
import { convertDateformate } from "../../../../../utils";
import { MdDelete } from "react-icons/md";
import { Row, Col, Form, DropdownButton, Dropdown } from "react-bootstrap";

import { AiOutlineFileAdd } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { FiTrash } from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import GetTranslatedConstants from "../../components/newcustomer/components/contant1";

function VieWContact({ id, setAddCustomer, setContactClients }) {
  const {CATEGORY} =GetTranslatedConstants()
  const [loader, setLoader] = React.useState(false);
  const [contactlist, setContacts] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [filterCategory, setFilterCategory] = React.useState("");
  const navigate = useNavigate();
  const [contactId, setContactId] = React.useState(0);
  const [contactupdate, setContactupdate] = React.useState(false);

  const handleCreateclient = (data) => {
    navigate("/clients/contact/createclient/", { state: data });
  };

  const handleClose = () => {
    setShow(false);
    setContactId("");
  };
  const changeNavigation = (eid) => {
    setAddCustomer(true);
    if (!id) navigate(`/clients/contact/${eid}`);
    else {
      setContactClients(eid);
    }
  };

  const deleteContactHandler = (id) => {
    setShow(true);
    setContactId(id);
  };
  const filterCategoryApi = (category) => {
    setLoader(true);
    clientService
      .filterContactByCategory(category)
      .then((res) => {
        if (res) {
          setContacts(res);
        } else {
          setContacts([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteConfirm = () => {
    try {
      clientService
        .deleteContact(contactId)
        .then((res) => {
          if (res) {
            toast.success(res.message);
            setContactId("");
          }
        })
        .catch((err) => {
          if (err) toast.error("Erreur lors de la suppression");
        });
       if (filterCategory != "") {
        filterCategoryApi(filterCategory);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
    setShow(false);
  };
   React.useEffect(() => {
    if (!contactId && filterCategory === "") {
      setLoader(true);
      clientService
        .getAllcontact()
        .then((intervient) => {
          setContacts(intervient);
          setLoader(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [contactupdate, contactId]);
  const onFilterByCategory = (e) => {
    setFilterCategory(e);
    filterCategoryApi(e);
    setLoader(false);
  };
 
  const { t } = useTranslation("global");

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Es-tu sûr !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Vous souhaitez supprimer le contact</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleDeleteConfirm}>
            {t("Oui")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row>
        <div className="d-flex justify-content-between mb-3">
          <Col md={4}></Col>
          <Col md={3}>
            <Form.Control
              as="select"
              onChange={(e) => onFilterByCategory(e.target.value)}
            >
              <option value="">{t("Choisir une catégorie")}</option>
              {CATEGORY.map((x) => (
                <option value={x.value}>{x.label}</option>
              ))}
            </Form.Control>
          </Col>
        </div>
      </Row>

      <Table striped responsive>
        <thead>
          <tr>
            <th></th>
            <th>{t("Nom")}</th>
            <th>{t("E-mail")}</th>
            <th>{t("Téléphone")}</th>
            <th>{t("Catégorie")}</th>
            <th>{t("Commentaires")}</th>
            <th style={{ width: "100px" }}>{t("Créer sur")}</th>
            <th style={{ width: "90px" }} className="text-center">
              {t("Action")}
            </th>
          </tr>
        </thead>
        <tbody>
          {contactlist?.length ? (
            contactlist?.map((emp, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                 <td>
                  <span className="text-capitalize">{emp?.client_name}</span>
                </td>
                <td>{emp?.email || "-"} </td>
                <td>{emp?.telephone || "-"}</td>
                <td>
                  {" "}
                  {(CATEGORY?.length &&
                    CATEGORY.filter((x) => x?.value == emp?.category)[0]
                      ?.label) ||
                    "-"}
                </td>

                <td> {emp?.comments || "-"}</td>
                <td> {convertDateformate(emp?.added_on) || "-"}</td>

                <td>
                  <Row className="g-2 ">
                    <Col md={7}>
                      <DropdownButton
                        
                        title={<HiOutlineDotsVertical />}
                         variant=""
                        
                         
                      
                      >
                        <Dropdown.Item onClick={() => handleCreateclient(emp)}>
                          <AiOutlineFileAdd className="me-2" /> {t("Ajouter Clients")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => changeNavigation(emp?.contact_id)}
                        >
                          <GrView className="me-2" /> { t("Voir")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => deleteContactHandler(emp?.contact_id)}
                        >
                          <FiTrash
                            size={20}
                            style={{ cursor: "pointer" }}
                            className="mt-1 text-danger me-2"
                          />{" "}
                          {t("Supprimer")}
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                    <Col md={3}>
                      {/* <DropdownButton
            id="dropdown-basic-button"
            title={<MdDelete size={20} style={{ cursor: "pointer" }} className="mt-1 text-danger" />}
            variant="primary"
          > */}
                      {/* <Dropdown.Item onClick={() => deleteContactHandler(emp?.contact_id)}>
              Supprimer
            </Dropdown.Item> */}
                      {/* </DropdownButton> */}
                    </Col>
                  </Row>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9} className="text-center">
                {loader ? <Loader /> : "Aucun contact trouvé"}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default VieWContact;
