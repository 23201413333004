import React from 'react'
import "./loader.css";

function Loader() {
  return (
    
    <span className="loader  "></span>

  )
}

export default Loader