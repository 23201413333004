import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { Controller, useFieldArray } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { BsSave2 } from "react-icons/bs";
import CreatableSelect from "react-select/creatable";
import { useNavigate, useParams ,useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
 import {
  listofGender,
  preferred_language,
  typeOfOption,
  martial_status,
  frequency_services,
} from "../../components/newcustomer/components/constant";
 import useSettings from "../../../../../hooks";
import clientService from "../../../../../services/clientservice";
import { extraValues } from "../utils";
import { useTranslation } from "react-i18next";
registerLocale("fr", fr);

function ClientForm() {

  const location = useLocation();
  const { t } = useTranslation("global");

  const { id } = useParams();
  const [clientData, setclientData] = useState();
  const [loader, setLoader] = React.useState(false);

  const [specialInterest, setSpecialInterest] = useState(false);
  const {
    register,
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState,
    errors,
    setError,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "customer_referred_to_the_team",
  });
  const {
    getPartners,
    servicelist,
    countrylist,
    immigrationOption,
    income_sourceOption,
    empemail,
    employees,
    languagelist,
    documentlist
  } = useSettings();
  const navigate = useNavigate();
  const customer_referred = watch("customer_referred_to_the_team");
  useEffect(() => {
    if (customer_referred?.length == 0) {
      append({ key: "", value: "" });
    }
  }, [customer_referred]);
  const [show, setShow] = React.useState(false);

  
  const extraSelectValues = async (selection, type) => {
    const extra_select = Array.isArray(selection) ? selection?.[0] : selection;

    if (extra_select) {
      const newData = extra_select.label == extra_select.value;
      const existData = extra_select.label != extra_select.value;
      if (newData) {
        const payload = {
          name: extra_select.label,
          type: type,
          entry: "MANUAL",
        };

        const insertpayload = await clientService.insertSetting(payload);
        return insertpayload.worker_id;
      }

      if (existData) {
        return extra_select?.value;
      }
    }
  };

  const ontestSubmit = async (data) => {
  
    setLoader(true);

    data.type = data?.type?.map((customer) => customer?.value)?.join(",");
    data.added_by = data.added_by.value;
    data.language_spoken = data?.language_spoken?.length
      ? data?.language_spoken?.map((x) => x?.value)?.join(",")
      : "";
    data.mother_tongue = data?.mother_tongue?.length
      ? data?.mother_tongue?.map((x) => x?.value)?.join(",")
      : "";

      data.type_of_id_submitted = data.type_of_id_submitted.value;
      


    const recommended_partners = await extraValues(
      data.recommended_partner,
      "PARTNER"
    );
    data.recommended_partner = recommended_partners;

    const referred_to_data = await extraValues(data.referred_to, "PARTNER");
    data.referred_to = referred_to_data;

    const x = data?.customer_referred_to_the_team
      ? data?.customer_referred_to_the_team
      : "";
    const flattenedArray = [].concat(...x);

    const refered_team_emaillist = data?.customer_referred_to_the_team
      ?.map((x) => x?.value)
      ?.toString();
    data.refered_team_email = refered_team_emaillist;

    if (flattenedArray?.find((x) => x.key != "")) {
      const customer_referred_to_the_teams = await extraValues(
        flattenedArray?.map((x) => x?.key),
        "EMPLOYEE"
      );

      data.customer_referred_to_the_team = customer_referred_to_the_teams;
    } else {
      data.customer_referred_to_the_team = "";
    }
    //MULITISELECT

    const immigration_status_data = await extraSelectValues(
      data.immigration_status,
      "STATUS_IMMIGRATION"
    );
    data.immigration_status = immigration_status_data;

    const income_sources = await extraSelectValues(
      data.income_source,
      "SOURCE_REVENUE"
    );
    data.income_source = income_sources;
     const native_countries = await extraSelectValues(
      data.native_country,
      "COUNTRY"
    );
    data.native_country = native_countries;

    data.special_interests_or_needs = data?.special_interests_or_needs
      ?.map((x) => x?.value)
      .toString();

    data.other_special_interests_or_needs =
      data.other_special_interests_or_needs;

    //DATE OPTIONS
    data.business_registration_date = new Date(
      data.business_registration_date
    ).getTime();

    data.dob = new Date(data.dob).getTime();
    data.date_of_arrival = new Date(data.date_of_arrival).getTime();
    data.frequency_service = data?.frequency_service?.value;
 
      clientService
        ?.addClientInfo(data)
        .then((res) => {
          if (res) {
            setShow(true);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(Object.values(error?.msg[0]));
        });
   
  };

  useEffect(()=>{
    setValue("client_name", location.state.client_name);
    setValue("email", location.state.email);
    setValue("telephone", location.state.telephone);
    setValue("comments", location.state?.comments);
   },[location])
  useEffect(() => {
    if (id) {
      clientService
        .getClientInfoById(id)
        .then((data) => {
          setclientData(data?.client);
        })
        .catch((error) => {
          console.log(error);
          setclientData([]);
        });
    } else {
      setValue("business_registration_date", new Date());
    }
  }, [id]);
 
  useEffect(() => {
    if (Object.keys(clientData || {}).length) {
      setValue(
        "business_registration_date",
        clientData?.business_registration_date
      );
      const dobdate = clientData?.dob;
      setValue("dob", dobdate);
      setValue("client_name", clientData?.client_name);
      setValue("surname", clientData?.surname);
      setValue("marital_status", clientData?.marital_status);
      setValue("telephone", clientData?.telephone);
      setValue("email", clientData?.email);
      setValue("address", clientData?.address);
      setValue("age", clientData?.age);
      setValue("date_of_arrival", clientData?.date_of_arrival);
      setValue("city", clientData?.city);
      setValue("state", clientData?.state);
      setValue("postal_code", clientData?.postal_code);
      setValue(
        "mother_tongue",
        languagelist?.filter((x) => x?.value == clientData?.mother_tongue)?.[0]
      );

      const mother_tongue_data = clientData?.mother_tongue?.length ? clientData?.mother_tongue
        .split(",") :[]
        ?.map((x) => x);
      const mother_tongue_data_patch = languagelist?.filter((data) =>
        mother_tongue_data?.some((x) => x == data.value)
      );
      setValue("mother_tongue", mother_tongue_data_patch);

      const language_spoken_data = clientData?.language_spoken ? clientData?.language_spoken
        .split(",")
        ?.map((x) => x) :[];
      const language_spoken_data_patch = languagelist?.filter((data) =>
        language_spoken_data?.some((x) => x == data.value)
      );
      setValue("language_spoken", language_spoken_data_patch);

      setValue("number_of_children", clientData?.number_of_children);
      setValue("preferred_language", clientData?.preferred_language);
      setValue("gender", clientData?.gender);
      setValue("added_by", {
        label: empemail?.filter((x) => x.value == clientData?.added_by)[0]
          ?.label,
        value: clientData?.added_by,
      });
      setValue(
        "immigration_status",
        immigrationOption?.filter(
          (x) => x?.value == clientData?.immigration_status
        )?.[0]
      );

      setValue("frequency_service", {
        label: clientData?.frequency_service,
        value: clientData?.frequency_service,
      });

      setValue(
        "income_source",
        income_sourceOption?.filter(
          (x) => x?.value == clientData?.income_source
        )?.[0]
      );

      setValue("school_level", clientData?.school_level);
      const recommended_partner = clientData?.recommended_partner?.length ? clientData?.recommended_partner
        .split(",")
        ?.map((x) => x) :[];
      const recommended_partner_patch = getPartners?.filter((data) =>
        recommended_partner?.some((x) => x == data.value)
      );
      setValue("recommended_partner", recommended_partner_patch);

      const referred_to_data = clientData?.referred_to?.length ? clientData?.referred_to.split(","):[];
      const referred_to_data_patch = getPartners?.filter((data) =>
        referred_to_data?.some((x) => x == data.value)
      );

      setValue("referred_to", referred_to_data_patch);
      setValue("type_of_id_submitted", documentlist.filter(x=>x.value==clientData?.type_of_id_submitted));
      setValue("paid_or_unpaid", clientData?.paid_or_unpaid);
      if (clientData?.other_special_interests_or_needs) {
        setSpecialInterest(true);
      }
      setValue(
        "other_special_interests_or_needs",
        clientData?.other_special_interests_or_needs
      );

      const type_patch = clientData?.type?.length ? clientData?.type.split(",")?.map((ty) => {
        return {
          label: ty,
          value: ty,
        };
      }):[];
      setValue("type", type_patch);

      setValue("native_country", {
        label: countrylist?.filter(
          (x) => x.value == clientData?.native_country
        )[0]?.label,
        value: clientData?.native_country,
      });

      const customer_referred_to_the_team_data = clientData?.customer_referred_to_the_team?.length ?
        clientData?.customer_referred_to_the_team.split(",")?.map((x) => x):[];
      const customer_referred_to_the_team_patch = empemail?.filter((data) =>
        customer_referred_to_the_team_data?.some((x) => x == data.value)
      );
      const ref_email = clientData?.refered_team_email?.split(",");
      const customer_referred_data = customer_referred_to_the_team_patch.map(
        (item, i) => ({
          key: item,
          value: ref_email[i],
        })
      );
      setValue("customer_referred_to_the_team", customer_referred_data);

      const special_interests_or_needs_data = clientData?.special_interests_or_needs?.length ?
        clientData?.special_interests_or_needs.split(",") :[];
      const special_interests_or_needs_patch = servicelist?.filter((data) =>
        special_interests_or_needs_data?.some((x) => x == data.value)
      );

      setValue("special_interests_or_needs", special_interests_or_needs_patch);
      setValue("comments", clientData?.comments);
    }
  }, [clientData, getPartners, empemail, servicelist]);
   const handleClose = () => {
    setShow(false)
  };
  const handleAddClient=()=>{
    clientService.addToClient(location.state.contact_id).then(res=>{
            toast.success("Ajouté avec succès à la liste des clients");
            setShow(false)
            navigate("/clients/customers");


    }).catch(err=>console.log(err))
   }

  return (
    <>

       <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Es-tu sûr !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Le contact sera supprimé de la liste
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleAddClient}>
          D'accord 
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mt-3">
        <form onSubmit={handleSubmit(ontestSubmit)}>
          <Row>
            <Col md={6}>
              {/* enregistrement */}
              <Row className="mb-4">
                <Col md={6}>
                  <Form.Label className="text-secondary fw-bold">
                    Date d’enregistrement *
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Controller
                    name="business_registration_date"
                    control={control}
                    rules={{ required: "Ce champ est obligatoire" }}
                    render={({ field }) => (
                      <div>
                        <DatePicker
                          locale="fr"
                          selected={field.value || new Date()}
                          onChange={(date) => {
                            field.onChange(date);
                            if (
                              formState.errors["business_registration_date"]
                            ) {
                              setError("business_registration_date", {
                                type: "manual",
                                message: "",
                              });
                            }
                          }}
                          className="form-control placeholder-color"
                          dateFormat="yyyy/MM/dd"
                          placeholderText="AAAA/MM/JJ"
                          wrapperClassName="datePicker"
                        />
                        {formState.errors.business_registration_date && (
                          <p className="text-danger">
                            {
                              formState.errors.business_registration_date
                                .message
                            }
                          </p>
                        )}
                      </div>
                    )}
                  />
                </Col>
              </Row>

              {/* Langue préférée */}
              <Row className="mb-4">
                <Col md={6}>
                  <Form.Label className="text-secondary fw-bold">
                    Langue préférée *
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Control
                    as="select"
                    {...register("preferred_language", {
                      required: "Ce champ est obligatoire",
                    })}
                  >
                   <option value="">{t("Sélectionner")}</option>

                    {preferred_language?.map((preferred_lang, id) => (
                      <option key={id} value={preferred_lang?.label}>
                        {preferred_lang?.label}
                      </option>
                    ))}
                  </Form.Control>
                  {formState?.errors?.preferred_language && (
                    <p className="text-danger">
                      {formState?.errors?.preferred_language?.message}
                    </p>
                  )}
                </Col>
              </Row>

              {/* Nom */}

              <Row className="mb-4">
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="firstname">
                    <Form.Label className="text-secondary fw-bold">
                      Nom *
                    </Form.Label>{" "}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    {...register("client_name", {
                      required: "Ce champ est obligatoire",
                    })}
                  />
                  {formState?.errors?.client_name && (
                    <p className="text-danger">
                      {formState?.errors?.client_name.message}
                    </p>
                  )}
                </Col>
              </Row>

              {/* Prénom */}
              <Row className="mb-4">
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="lastname">
                    <Form.Label className="text-secondary fw-bold">
                      Prénom *
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Control
                    type="text"
                    {...register("surname", {
                      required: "Ce champ est obligatoire",
                    })}
                  />
                  {formState?.errors?.surname && (
                    <p className="text-danger">
                      {formState?.errors?.surname?.message}
                    </p>
                  )}
                </Col>
              </Row>

              {/* Genre */}
            </Col>
          </Row>

          <Row>
            {/* Genre   */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  Genre *
                </Form.Label>

                <Form.Control
                  as="select"
                  {...register("gender", {
                    required: "Ce champ est obligatoire",
                  })}
                >
                   <option value="">{t("Sélectionner")}</option>

                  {listofGender?.map((gender, id) => (
                    <option key={id} value={gender?.label}>
                      {gender?.label}
                    </option>
                  ))}
                </Form.Control>
                {formState?.errors?.gender && (
                  <p className="text-danger">
                    {formState?.errors?.gender?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            {/* État civil-- */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  État civil *
                </Form.Label>
                <Form.Select
                  {...register("marital_status", {
                    required: "Ce champ est obligatoire",
                  })}
                >
                                   <option value="">{t("Sélectionner")}</option>

                  {martial_status.map((status) => (
                    <option value={status?.value}>{status?.label}</option>
                  ))}
                </Form.Select>
                {formState?.errors?.marital_status && (
                  <p className="text-danger">
                    {formState?.errors?.marital_status?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Date de naissance * */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="text-secondary fw-bold">
                  Date de naissance *
                </Form.Label>
                <br />

                <Controller
                  name="dob"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <>
                      <DatePicker
                        locale="fr"
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          if (formState.errors["dob"]) {
                            setError("dob", {
                              type: "manual",
                              message: "",
                            });
                          }
                        }}
                        className="form-control placeholder-color"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="AAAA/MM/JJ"
                        wrapperClassName="datePicker"
                      />
                      {formState.errors.dob && (
                        <p className="text-danger">
                          {formState.errors.dob.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </Col>
            {/* Téléphone */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label className="text-secondary fw-bold">
                  Téléphone *
                </Form.Label>

                <Controller
                  name="telephone"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <>
                      <Form.Control
                        className="placeholder-color"
                        {...field}
                        type="text"
                        maxLength={14}
                        placeholder="XXX-XXX-XXXX"
                        pattern="\d{3}-\d{3}-\d{4}"
                        onChange={(e) => {
                          const unformattedValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const formattedValue = unformattedValue.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "$1-$2-$3"
                          );
                          field.onChange(formattedValue);
                          if (formState.errors["telephone"]) {
                            setError("telephone", {
                              type: "manual",
                              message: "",
                            });
                          }
                        }}
                      />
                      {formState.errors.telephone && (
                        <p className="text-danger">
                          {formState.errors.telephone.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {/* Courriel */}
            <Col md={12}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="text-secondary fw-bold">
                  Courriel *
                </Form.Label>
                <Form.Control
                  type="email"
                  {...register("email", {
                    required: "Ce champ est obligatoire",
                  })}
                />
                {formState?.errors?.email && (
                  <p className="text-danger">
                    {formState?.errors?.email?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          {/* Address */}
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="address">
                <Form.Label className="text-secondary fw-bold">
                  Adresse *{" "}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  type="text"
                  {...register("address", {
                    required: "Ce champ est obligatoire",
                  })}
                />
                {formState?.errors?.address && (
                  <p className="text-danger">
                    {formState?.errors?.address?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={5}>
              <Form.Group className="mb-3" controlId="State">
                <Form.Label className="text-secondary fw-bold">
                  Ville *{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("city", {
                    required: "Ce champ est obligatoire",
                  })}
                />
                {formState?.errors?.city && (
                  <p className="text-danger">
                    {formState?.errors?.city?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="State">
                <Form.Label className="text-secondary fw-bold">
                  Province *{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("state", {
                    required: "Ce champ est obligatoire",
                  })}
                />
                {formState?.errors?.state && (
                  <p className="text-danger">
                    {formState?.errors?.state?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="State">
                <Form.Label className="text-secondary fw-bold">
                  Code Postal *{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("postal_code", {
                    required: "Ce champ est obligatoire",
                  })}
                />
                {formState?.errors?.postal_code && (
                  <p className="text-danger">
                    {formState?.errors?.postal_code?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Pays d'origine  */}

            <Col md={6}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label className="text-secondary fw-bold">
                  Pays d'origine *
                </Form.Label>
                <Controller
                  name="native_country"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <CreatableSelect
                      placeholder={t("Sélectionner")}
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["native_country"]) {
                          setError("native_country", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      isClearable
                      {...field}
                      options={countrylist}
                    />
                  )}
                />
                {formState.errors.native_country && (
                  <p className="text-danger">
                    {formState.errors.native_country.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            {/* Langues parlées */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                  Langues parlées *
                </Form.Label>

                <Controller
                  name="language_spoken"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <Select
                      isMulti
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["language_spoken"]) {
                          setError("language_spoken", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      placeholder={t("Sélectionner")}
                      {...field}
                      options={languagelist}
                    />
                  )}
                />
                {formState.errors.language_spoken && (
                  <p className="text-danger">
                    {formState.errors.language_spoken.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Langue maternelle  */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                  Langue maternelle *
                </Form.Label>
                <Controller
                  name="mother_tongue"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <Select
                      isMulti
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["mother_tongue"]) {
                          setError("mother_tongue", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      placeholder={t("Sélectionner")}
                      {...field}
                      options={languagelist}
                    />
                  )}
                />

                {formState.errors.mother_tongue && (
                  <p className="text-danger">
                    {formState.errors.mother_tongue.message}
                  </p>
                )}
              </Form.Group>
            </Col>

            {/* Date d'arrivée au Canada/au Québec  */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="address">
                <Form.Label className="text-secondary fw-bold">
                  Date d'arrivée au Canada/au Québec *{" "}
                </Form.Label>
                <br />
                <Controller
                  name="date_of_arrival"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <DatePicker
                      locale="fr"
                      selected={field.value}
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["date_of_arrival"]) {
                          setError("date_of_arrival", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      className="form-control placeholder-color"
                      dateFormat="yyyy/MM/dd"
                      placeholderText="AAAA/MM/JJ"
                      wrapperClassName="datePicker"
                    />
                  )}
                />
                {formState.errors.date_of_arrival && (
                  <p className="text-danger">
                    {formState.errors.date_of_arrival.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Statut d'immigration */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  Statut d'immigration *
                </Form.Label>

                <Controller
                  name="immigration_status"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <CreatableSelect
                      placeholder={t("Sélectionner")}
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["immigration_status"]) {
                          setError("immigration_status", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      {...field}
                      isClearable
                      options={immigrationOption}
                    />
                  )}
                />
                {formState.errors.immigration_status && (
                  <p className="text-danger">
                    {formState.errors.immigration_status.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            {/* Nombre d'enfants:  */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                  Nombre d'enfants *
                </Form.Label>
                <Form.Control
                  type="number"
                  {...register("number_of_children", {
                    required: "Ce champ est obligatoire",
                  })}
                />
              </Form.Group>
              {formState?.errors?.number_of_children && (
                <p className="text-danger">
                  {formState?.errors?.number_of_children?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            {/* Source de revenus */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  Source de revenu *
                </Form.Label>
                <Controller
                  name="income_source"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <CreatableSelect
                      placeholder={t("Sélectionner")}
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["income_source"]) {
                          setError("income_source", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      {...field}
                      options={income_sourceOption}
                    />
                  )}
                />
                {formState.errors.income_source && (
                  <p className="text-danger">
                    {formState.errors.income_source.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            {/* Niveau scolair */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  Niveau scolaire *
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register("school_level", {
                    required: "Ce champ est obligatoire",
                  })}
                >
                 <option value="">{t("Sélectionner")}</option>

                  <option value="Primaire">Primaire</option>
                  <option value="Secondaire">Secondaire</option>
                  <option value="Collégial">Collégial</option>
                  <option value="Universitaire">Universitaire</option>
                </Form.Select>
              </Form.Group>
              {formState?.errors?.school_level && (
                <p className="text-danger">
                  {formState?.errors?.school_level?.message}
                </p>
              )}
            </Col>
          </Row>

          <Row>
            {/* Intérêts ou besoins particuliers  */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label className="text-secondary fw-bold">
                  Intérêts ou besoins particuliers *{" "}
                  {!specialInterest ? (
                    <FaPlus
                      className=" ms-2"
                      size={15}
                      onClick={() => setSpecialInterest(true)}
                    />
                  ) : (
                    <IoClose
                      size={20}
                      className=" ms-2"
                      onClick={() => setSpecialInterest(false)}
                    />
                  )}
                </Form.Label>

                <Controller
                  name="special_interests_or_needs"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={servicelist}
                      isClearable
                      isMulti
                      placeholder={t("Sélectionner")}
                      onChange={(e) => {
                        field.onChange(e);

                        setValue("special_interests_or_needs", e);
                        if (formState.errors["special_interests_or_needs"]) {
                          setError("special_interests_or_needs", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                    />
                  )}
                />
                {specialInterest && (
                  <Form.Control
                    {...register("other_special_interests_or_needs")}
                    className="mt-3"
                    as="textarea"
                    rows={3}
                  />
                )}
              </Form.Group>
              {formState.errors.special_interests_or_needs && (
                <p className="text-danger">
                  {formState.errors.special_interests_or_needs.message}
                </p>
              )}
            </Col>

            {/* Durée ou fréquence du service  */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                  Durée ou fréquence du service *
                </Form.Label>
                <Controller
                  name="frequency_service"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <CreatableSelect
                      placeholder={t("Sélectionner")}
                      {...field}
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["frequency_service"]) {
                          setError("frequency_service", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      isClearable
                      options={frequency_services}
                    />
                  )}
                />
                {formState.errors.frequency_service && (
                  <p className="text-danger">
                    {formState.errors.frequency_service.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Référé à l’AFIO par */}

            <Col md={6}>
              <div>
                <label className="text-secondary fw-bold form-label">
                  Référé à l’ AFIO par *
                </label>
              </div>

              <Controller
                name="referred_to"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                render={({ field }) => (
                  <CreatableSelect
                    placeholder={t("Sélectionner")}
                    onChange={(date) => {
                      field.onChange(date);
                      if (formState.errors["referred_to"]) {
                        setError("referred_to", {
                          type: "manual",
                          message: "",
                        });
                      }
                    }}
                    {...field}
                    isMulti
                    formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
                    options={getPartners}
                  />
                )}
              />
              {formState.errors.referred_to && (
                <p className="text-danger">
                  {formState.errors.referred_to.message}
                </p>
              )}
            </Col>
            {/* Pièce d’identité soumise * */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                  Pièce d’identité soumise *
                </Form.Label>
                <Controller
                name="type_of_id_submitted"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                render={({ field }) => (
                  <Select
                    placeholder={t("Sélectionner")}
                    onChange={(date) => {
                      field.onChange(date);
                      if (formState.errors["type_of_id_submitted"]) {
                        setError("type_of_id_submitted", {
                          type: "manual",
                          message: "",
                        });
                      }
                    }}
                    {...field}
                    
                    formatCreateLabel={(inputValue) => `Créer "${inputValue}"`}
                    options={documentlist}
                  />
                )}
              />
              {formState.errors.type_of_id_submitted && (
                <p className="text-danger">
                  {formState.errors.type_of_id_submitted.message}
                </p>
              )}

                
                
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Rôle */}

            <Col md={6}>
              <div>
                <label className="text-secondary fw-bold form-label">
                  Rôle *
                </label>
              </div>
              <Controller
                name="type"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                render={({ field }) => (
                  <Select
                    onChange={(date) => {
                      field.onChange(date);
                      if (formState.errors["type"]) {
                        setError("type", {
                          type: "manual",
                          message: "",
                        });
                      }
                    }}
                    placeholder={t("Sélectionner")}
                    {...field}
                    isMulti
                    options={typeOfOption}
                  />
                )}
              />
              {formState.errors.type && (
                <p className="text-danger">{formState.errors.type.message}</p>
              )}
            </Col>

            {/* Cotisation payée  */}

            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  Cotisation payée *
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register("paid_or_unpaid", {
                    required: "Ce champ est obligatoire",
                  })}
                >
                  <option value="Oui">{t("Oui")}</option>
                  <option value="Non">{t("Non")}</option>
                </Form.Select>
              </Form.Group>
              {formState?.errors?.paid_or_unpaid && (
                <p className="text-danger">
                  {formState?.errors?.paid_or_unpaid?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            {/* Partenaires recommandés */}
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                  Référé à *
                </Form.Label>

                <Controller
                  name="recommended_partner"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <CreatableSelect
                      placeholder={t("Sélectionner")}
                      {...field}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["recommended_partner"]) {
                          setError("recommended_partner", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      isMulti
                      options={getPartners}
                    />
                  )}
                />
                {formState.errors.recommended_partner && (
                  <p className="text-danger">
                    {formState.errors.recommended_partner.message}
                  </p>
                )}
              </Form.Group>
            </Col>

            {/* Dossier attribué à */}

            <Col md={6}>
              <div>
                <label className="text-secondary fw-bold form-label">
                  Dossier attribué à{" "}
                  <FaPlus
                    className=" ms-2"
                    size={15}
                    onClick={() => append({ key: "", value: "" })}
                    Add
                    Option
                  />
                </label>
              </div>
              {fields.map((field, index) => (
                <div className="row mb-2" key={field.id}>
                  <div className="col-md-5">
                    <Controller
                      control={control}
                      name={`customer_referred_to_the_team[${index}].key`}
                      render={({ field }) => (
                        <CreatableSelect
                          placeholder={t("Sélectionner")}
                          {...field}
                          formatCreateLabel={(inputValue) =>
                            `Créer "${inputValue}"`
                          }
                          options={empemail}
                          onChange={(e) => {
                            setValue(
                              `customer_referred_to_the_team[${index}].key`,
                              e
                            );
                            setValue(
                              `customer_referred_to_the_team[${index}].value`,
                              e.email
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-5">
                    <Form.Control
                      type="email"
                      {...register(
                        `customer_referred_to_the_team[${index}].value`
                      )}
                      placeholder="Courriel"
                      className="placeholder-color"
                    />
                  </div>
                  <div className="col-md-2">
                    <FaRegTrashAlt
                      className="text-danger ms-2"
                      size={15}
                      onClick={() => remove(index)}
                      Remove
                    />
                  </div>
                </div>
              ))}
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                  Client ajouté par *
                </Form.Label>

                <Controller
                  name="added_by"
                  control={control}
                  rules={{ required: "Ce champ est obligatoire" }}
                  render={({ field }) => (
                    <CreatableSelect
                      placeholder={t("Sélectionner")}
                      {...field}
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["added_by"]) {
                          setError("added_by", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      options={empemail}
                    />
                  )}
                />
                {formState.errors.added_by && (
                  <p className="text-danger">
                    {formState.errors.added_by.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                  Commentaires ou notes *
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("comments", {
                    required: "Ce champ est obligatoire",
                  })}
                />
              </Form.Group>
              {formState?.errors?.comments && (
                <p className="text-danger">
                  {formState?.errors?.comments?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col md={1}>
              <button
                className="btn btn-primary btn-sm  "
                style={{ width: "120px" }}
                type="submit"
               >
                <BsSave2 className="me-1" />{" "}
                {id ? "Mise à jour" : "Sauvegarder"}
                
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
}

export default ClientForm;
