import { FaCalendarDays } from "react-icons/fa6";
import { GoHomeFill } from "react-icons/go";
import { MdPeopleAlt } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { BsCalendar2DateFill } from "react-icons/bs";
import { RiSettings3Line } from "react-icons/ri";
import { FaRegCalendarAlt } from "react-icons/fa";
import { PiWarningOctagonFill } from "react-icons/pi";
import { BiSolidReport } from "react-icons/bi";

const adminnavigationmenu = [
  {
    id: 1,
    name: "Tableau de bord",
    icon: GoHomeFill,
    path: "/",
  },
  {
    id: 2,
    name: "Nouveau client",
    icon: MdPeopleAlt,
    path: "/clients/customers",
  },
  {
    id: 3,
    name: "Calendrier",
    icon: FaCalendarDays,
    path: "/calendar",
  },
  {
    id: 4,
    name: "Générer un rapport",
    icon: BiSolidReport,
    // path: "/client",
    submenu: [
      {
        id: 31,
        name: "Interventions",
        icon: FaCalendarDays,
        path: "/speakerStatistics",
      },
      {
        id: 30,
        name: "Equipe impliquée",
        icon: BsCalendar2DateFill,
        path: "/teamReport",
      },
      {
        id: 33,
        name: "Calendrier",
        icon: RiSettings3Line,
        path: "/reportByDateTime",
      },
      {
        id: 32,
        name: "Services offerts",
        icon: BsCalendar2DateFill,
        path: "/serviceReport",
      },
      
      {
        id: 34,
        name: "Statut d'immigration",
        icon: FaRegCalendarAlt,
        path: "/status_immigration",
      },
      {
        id: 35,
        name: "Partenaires recommandés",
        icon: FaRegCalendarAlt,
        path: "/refferedToReport",
      },
      
      {
        id: 37,
        name: "Rapports personnalisés",
        icon: PiWarningOctagonFill,
        path: "/reports",
      },
       
    ],
  },

   
];

const fradminnavigationmenu = [
  {
    id: 1,
    name: "Dashboard",
    icon: GoHomeFill,
    path: "/",
  },
  {
    id: 2,
    name: "New Client",
    icon: MdPeopleAlt,
    path: "/manageuser",
  },

  {
    id: 3,
    name: "Generate a report",
    icon: MdPeopleAlt,
    // path: "/client",
    submenu: [
      {
        id: 31,
        name: "Intervening statistics",
        icon: FaCalendarDays,
        path: "/speakerStatistics",
      },

      {
        id: 32,
        name: "Team involved",
        icon: BsCalendar2DateFill,
        path: "/reportByDateTime",
      },
      {
        id: 33,
        name: "Calendar",
        icon: RiSettings3Line,
        path: "/serviceReport",
      },
      {
        id: 34,
        name: "Immigration status",
        icon: FaRegCalendarAlt,
        path: "/status_immigration",
      },
      {
        id: 35,
        name: "Recommended partners",
        icon: FaRegCalendarAlt,
        path: "/refferedToReport",
      },
      {
        id: 36,
        name: "Recommended project",
        icon: PiWarningOctagonFill,
        path: "/reports",
      },
      {
        id: 37,
        name: "Custom Reports ",
        icon: CgProfile,
        path: "/profile",
      },
    ],
  },
];

export { adminnavigationmenu };
