import moment from "moment";
 
const dateformate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const convertDateformate = (date) => {
   const dateObject = new Date(date * 1000);
  return moment(dateObject).format("YYYY-MM-DD");
};

const getTimeformat = (timeInSeconds, dates) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  
  const date = new Date(dates);
  date.setHours(hours, minutes, seconds);

  // Format the time in 12-hour format (AM/PM)
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",

    hour12: true,
  });
  return formattedTime;
};
const getTimeformat24hours = (timeInSeconds, dates) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  const date = new Date(dates);
  date.setHours(hours, minutes, seconds);

  // Format the time in 24-hour format
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });

  return formattedTime;
};

const get24hourstime=(date)=>{
  const newDate= new Date(date)
  const formattedTime = newDate.toLocaleTimeString("en-US", {
    
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
  return formattedTime
}
const convertotimestamp=(dateString)=>{
  return Date.parse(dateString) /1000;
}
function convertTimestampToUTC(timestamp) {
  const utcDate = new Date(timestamp * 1000);
  utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset());
   return utcDate;
}
 
const getTimes = (date) => {
  if(date){
     return new Date(date).toISOString().split("T")[0];
  }
  else{
    return date
  }
};

const newdateformat =(timestamp)=>{
   if(timestamp){
    let dateObject = new Date(timestamp);
    let formattedDate = dateObject.toISOString().slice(0, 19).replace("T", " ") + " UTC";
    return moment(formattedDate).format("YYYY-MM-DD");
    
  }
 }

 
export {getTimes,
  convertTimestampToUTC,
  getTimeformat,newdateformat,get24hourstime,
  dateformate,
  convertDateformate,convertotimestamp ,getTimeformat24hours
};
