import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { administrationLabel, intervenanteLabel } from '../../components/newcustomer/labels';
import clientService from '../../../../../services/clientservice';
import useSettings from '../../../../../hooks';
import { convertDateformate } from '../../../../../utils';
import Loader from '../../../../../utils/Loader';
import { useTranslation } from 'react-i18next';
 
function ViewAdministration({id ,setAddCustomer,setClientAdministrationId}) {
    const [adminiList, setAdminiList] = React.useState([]);
    const navigate = useNavigate();
    const [loader,setLoader] = React.useState(false);

    const {  empemail ,activity} = useSettings();

    const getAdmindetails = (data, defaultdata) => {
        const recordsplit = data?.split(",");
 
        const arr = [];
        recordsplit?.length && recordsplit?.map((parter) => {
 
          const matchingItem = defaultdata?.find((item) => item?.value === parter);
 
          if (matchingItem) {
            arr.push(matchingItem.label);
          }
        });
         return arr.join(",");
      };
       const changeNavigation=(eid)=>{
        setAddCustomer(true)
       if(!id) navigate(`/clients/administration/${eid}`)
       else{ 
        setClientAdministrationId(eid);
     }
      }
      React.useEffect(()=>{
        setLoader(true)
        if(id){
          clientService
          .getAdmininfo(id)
          .then((intervient) => {
            setAdminiList(intervient);
            setLoader(false)

          })
          .catch((error) => {
            console.error(error);
          });
        }
        else{
          clientService
          .getAllAdmini()
          .then((admini) => {
            if (admini) {setAdminiList(admini);
              setLoader(false)
            }
            else setAdminiList([]);
          })
          .catch((error) => {
            console.error(error);
          });
        } 
        },[id , activity ,empemail])
        const { t } = useTranslation("global");

   return (
    <Table striped>
    <thead>
      <tr>
        <th></th>
         <th>Client</th>
        <th>{t("Équipe impliquée")}</th>
        <th>{t("Projets recommandés")}</th>
         <th>{t("Créé sur")}</th>
      </tr>
    </thead>
    <tbody>
      {adminiList?.length ? (
        adminiList?.map((admin, idx) => (
          <tr
            key={idx}
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeNavigation(admin?.administration_id)
            }}
            
          >
             <td>{idx + 1}</td>
            <td>{admin?.client_name ? admin?.client_name:"-"}</td>
            <td>{admin?.team_involved ? getAdmindetails(admin?.team_involved, empemail) :"-"}</td>
            <td>{admin?.recommended_project ? getAdmindetails(admin?.recommended_project, activity):"-"} </td>
             <td> {convertDateformate(admin?.added_on) || "-"}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={6} className="text-center">
             {loader ? <Loader/> : "Aucun Administration trouvé"}

          </td>
        </tr>
      )}
    </tbody>
  </Table>
  )
}

export default ViewAdministration