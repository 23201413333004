import React from "react";
import { Form, Button,Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ForgetWrapper from "./authstyle/ForgetWrapper";
import authService from "../../services/authservice/authservice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { IoReturnUpBackOutline } from "react-icons/io5";

function ForgetPage() {
  const navigate = useNavigate();
  const [errormsg,setErrorMessage] = React.useState("");

  const {
    register, 
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    authService
      .forgetByusername(data)
      .then((res) => {
        if (res) {
          setErrorMessage("")
             navigate('/resetpassword', { state: { user_id: res?.user_id} });
             toast.success("Utilisateur vérifié");

            }
         else{
            navigate("/forgetpassword");
            setErrorMessage("Utilisateur non trouvé");

 
         }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ForgetWrapper>
       <button
            className="btn btn-primary btn-sm ms-1 justify-content-start"
            onClick={() => navigate(`/login`)}
          >
            <IoReturnUpBackOutline className="me-1" size={20} /> Dos
          </button>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <Form.Group>
        <Form.Label className="fw-bold">Nom d'utilisateur</Form.Label>
          <Form.Control
            type="text"
            placeholder="Saisissez votre nom d'utilisateur"
            {...register("username", {
              required: "Veuillez saisir votre nom d'utilisateur",
            })}
            isInvalid={!!errors.username}
          />
          <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
            {errors.username && errors.username.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="btn-block w-100 mt-3"
        >
          <span className="fw-bold">Se connecter</span>
        </Button>
        {errormsg && (
                  <Form.Group className="mt-3">
                    <Alert variant="danger">{errormsg}</Alert>
                  </Form.Group>
                )}
      </Form>
    </ForgetWrapper>
  );
}

export default ForgetPage;
