import { useState, useEffect } from "react";
import clientService from "../services/clientservice";

const useSettings = () => {
  const [getPartners, setPartners] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [servicelist, setServiceList] = useState([]);
  const [immigrationOption, setimmigrationOption] = useState([]);
  const [income_sourceOption, setincome_sourceOption] = useState([]);
  const [countrylist, setCountryList] = useState([]);
  const [intervenerservice, setIntervereservice] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [nom, setNom] = useState([]);
  const [empemail, setEmpEmail] = useState([]);
  const [languagelist,setLanguageList ] = useState([])
  const [activity,setActivity] =useState([]);
  const [documentlist,setDocumentList] =useState([])
  
  function sortListmethod(list) {
     return list.sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();
  
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await clientService.getAllsettings();
        if (response) {
          const serviceobj = response?.services?.length
            ? response?.services?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj1 = sortListmethod(serviceobj);

            const intervenerserviceobj = response?.intervenerservice?.length
            ? response?.intervenerservice?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj2 = sortListmethod(intervenerserviceobj);


            const languageObj = response?.languageList?.length
            ? response?.languageList?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedLang = sortListmethod(languageObj);

            const docObj = response?.documentlist?.length
            ? response?.documentlist?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedDocs = sortListmethod(docObj);


           const empobj = response?.employees?.length
            ? response?.employees?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj3 = sortListmethod(empobj)
             const empemailobj = response?.emp_email?.length
            ? response?.emp_email?.map((e) => {
                return { label: e?.name, value: e?.worker_id ,email: e?.email };
              })
            : [];
            const sortedObj11 = sortListmethod(empemailobj)


            const nomobj = response?.nom?.length
            ? response?.nom?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj4 =  sortListmethod(nomobj)

          const countryobj = response?.countries?.length
            ? response?.countries?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj5 =  sortListmethod(countryobj)

          const parterobj = response?.partners?.length
            ? response?.partners?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj6 =   sortListmethod(parterobj)

          const immigrationsObj = response?.immigrations?.length
            ? response?.immigrations?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj7 =   sortListmethod(immigrationsObj)

          const income_typeObj = response?.income_type?.length
            ? response?.income_type?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj8 =   sortListmethod(income_typeObj);

            const activityList = response?.activity?.length
            ? response?.activity?.map((e) => {
                return { label: e?.name, value: e?.worker_id };
              })
            : [];
            const sortedObj9 =   sortListmethod(activityList)

          setEmployees(sortedObj3);
          setCountryList(sortedObj5);
          setPartners(sortedObj6);
          setServiceList(sortedObj1);
          setimmigrationOption(sortedObj7);
          setincome_sourceOption(sortedObj8);
          setIntervereservice(sortedObj2);
          setNom(sortedObj4)
          setActivity(sortedObj9)
          setEmpEmail(sortedObj11);
          setLanguageList(sortedLang)
          setDocumentList(sortedDocs)
        }
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

    return {
    loading,activity,
    error,
    getPartners,
    employees,
    servicelist,
    intervenerservice,
    income_sourceOption,
    immigrationOption,
    countrylist,
    nom,
    empemail,
    languagelist,
    documentlist
  };
};

export default useSettings;
