import React from "react";
import Card from "react-bootstrap/Card";
import { BsFillPeopleFill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import AddClient from "./addclient";
import VieWClient from "./viewclient";
import { useTranslation } from "react-i18next";

function ClientLookup() {
  const [addcustomer, setAddCustomer] = React.useState(false);
  const { t } = useTranslation("global");

  return (
    <Card className="mt-3">
      <Card.Header>
        {!addcustomer ? (
          <div className="d-flex m-2 justify-content-between align-items-center">
            <p className="align-items-center h6 fs-12  mt-2">
              {" "}
              <BsFillPeopleFill size={20} className="me-2" />
              Clients
            </p>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setAddCustomer(true)}
            >
              <FiPlus size={20} className="me-2" /> {t("Ajouter client")}
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-start m-2">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setAddCustomer(false)}
            >
              <IoReturnUpBackOutline className="me-1" size={20} />
              {t("Retour")}
            </button>
          </div>
        )}
      </Card.Header>
      <Card.Body>
        {addcustomer ? (
          <AddClient setAddCustomer={setAddCustomer} />
        ) : (
          <VieWClient />
        )}
      </Card.Body>
    </Card>
  );
}

export default ClientLookup;
