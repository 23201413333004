import React from "react";
import { Form, Button,Row,Col,Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ForgetWrapper from "./authstyle/ForgetWrapper";
import authService from "../../services/authservice/authservice";
import { useNavigate ,useLocation} from "react-router-dom";
import { toast } from "react-hot-toast";
import { IoReturnUpBackOutline } from "react-icons/io5";


function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [errormsg,setErrorMessage] = React.useState("");
  const { user_id } = location.state || {};
   const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if(data?.password == data?.cpassword){
        const payload ={
            user_id : user_id,
            password:data?.password,
         }
        authService
          .resetPassword(payload)
          .then((res) => {
            if (res) {
              setErrorMessage("")
                toast.success("Mot de passe changé");
              navigate("/login");
            }
            else{
              setErrorMessage("Échec de la modification du mot de passe");
                 navigate("/forgetpassword");
            }
          })
          .catch((err) => {
            navigate("/forgetpassword");
            console.log(err);
          });
    }
    
    else{
       setErrorMessage("Non concordance des mots de passe");
     }
  };
  return (
    <ForgetWrapper>
       <button
            className="btn btn-primary btn-sm ms-1 justify-content-start"
            onClick={() => navigate(`/login`)}
          >
            <IoReturnUpBackOutline className="me-1" size={20} /> Dos
          </button>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="password" className="fw-bold">Nouveau mot de passe</Form.Label>
              <Form.Control
                {...register("password", {
                  required: "Veuillez entrer le mot de passe", 
                  minLength: {
                    value: 6,
                    message: 'Veuillez saisir au moins 6 caractères',
                  },
                  maxLength: {
                    value: 20,
                    message: 'Le mot de passe ne peut pas dépasser 20 caractères',
                  },
                })}
                isInvalid={!!errors.password}
                type="password"
                width="100px"
                placeholder="Entrez un nouveau mot de passe"
              />
              {errors.password && <p className="text-danger">{errors.password.message}</p>}
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="Confirm" className="fw-bold">Confirmez le mot de passe</Form.Label>
              <Form.Control
                {...register("cpassword", 
                {
                minLength: {required: "Veuillez saisir le mot de passe confirmé",
                value: 6, message: 'Veuillez saisir au minimum 6 caractères' }, 
                maxLength: 20  })}
                isInvalid={!!errors.cpassword}
                type="password"
                width="100px"
                placeholder="Entrez Confirmer le mot de passe"
              />
              {errors.cpassword && <p className="text-danger">{errors.cpassword.message}</p>}
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary"
          type="submit"
          className="btn-block w-100 mt-3"
        >
          <span className="fw-bold">Se connecter</span>
        </Button>


        {errormsg && (
                  <Form.Group className="mt-3">
                    <Alert variant="danger">{errormsg}</Alert>
                  </Form.Group>
                )}



      </Form>
    </ForgetWrapper>
  );
}

export default ResetPassword;
