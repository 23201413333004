import React, { useEffect, useState } from "react";
import sidestyle from "./sidebar.module.css";
import { adminnavigationmenu } from "./contant";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IoLogOut } from "react-icons/io5";
import { logout } from "../../store/slices/authslice";
import { useDispatch, useSelector } from "react-redux";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import logo from "../../asset/logo1.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";

function Sidebar(props) {
  const { t } = useTranslation("global");
  const [usertypenavigation, setUserTypeNavigation] = useState([]);
  const [newuser, Setnewuser] = useState(false);
  React.useEffect(() => {
    setUserTypeNavigation(adminnavigationmenu);
   }, []);
  const location = useLocation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const [openSubmenuId, setOpenSubmenuId] = useState(null);
  const toggleSubmenu = (submenuId) => {
    setOpenSubmenuId((prevId) => (prevId === submenuId ? null : submenuId));
  };

  const iconSize = window.innerWidth > 768 ? 16 : 12;
  // useEffect (()=>{
  //   Setnewuser(user?.username === "admin@datajus.ca")
  // },[])
  return (
    <>
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#8d404a"
        style={{ height: "100vh" }}
      >
        <CDBSidebarHeader className="border-0">
          <div className="d-flex justify-content-center">
            <img src={logo} alt="logo" width="60%" className="rounded-4" />
          </div>
        </CDBSidebarHeader>

        <CDBSidebarContent
          className={` ${sidestyle.scrollbarstyle}`}
          style={{ overflowY: "auto" }}
        >
          <CDBSidebarMenu>
            {usertypenavigation?.map((menu, idx) => {
              const isActive = location.pathname === menu.path;

              return (
                <Link
                  key={idx}
                  to={menu.path}
                  className={`nav-link ${
                    isActive ? "active" : ""
                  } mx-3 rounded-3`}
                  style={{
                    backgroundColor: isActive ? "#ffffff4e" : "transparent",
                    color: isActive ? "white" : "white",
                  }}
                >
                  <CDBSidebarMenuItem onClick={() => toggleSubmenu(menu.id)}>
                    <span style={{ marginRight: "10px" }}>
                      {" "}
                      {React.createElement(menu.icon, {
                        size: iconSize,
                        color: isActive ? "white" : "white",
                      })}
                    </span>

                    <span className="ms-2" style={{ fontSize: "14px" }}>
                      {t(`${menu?.name}`)}
                    </span>
                    {menu.submenu && (
                      <span className="ms-2">
                        {openSubmenuId === menu.id ? (
                          <IoIosArrowDown />
                        ) : (
                          <IoIosArrowUp />
                        )}
                      </span>
                    )}
                  </CDBSidebarMenuItem>
                  {menu.submenu && openSubmenuId === menu.id && (
                    <CDBSidebarMenu>
                      {menu.submenu.map((submenuItem, idx) => (
                        <OverlayTrigger
                          key={idx}
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={`tooltip-${submenuItem.id}`}>
                              {t(submenuItem.name)}
                            </Tooltip>
                          }                        >
                          <Link
                            key={submenuItem.id}
                            to={submenuItem.path}
                            className={`nav-link ${
                              location.pathname === submenuItem.path ? "" : ""
                            } mx-3 rounded-3`}
                            style={{
                              backgroundColor:
                                location.pathname === submenuItem.path
                                  ? "#ffffff4e"
                                  : "transparent",
                            }}
                          >
                            <CDBSidebarMenuItem>
                              <span
                                className={`ms-4 text-white ${sidestyle.font_size}`}
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {t(`${submenuItem?.name}`)}
                              </span>
                            </CDBSidebarMenuItem>
                          </Link>
                        </OverlayTrigger>
                      ))}
                    </CDBSidebarMenu>
                  )}
                </Link>
              );
            })}

            <Link
              to="/logout"
              onClick={() => {
                dispatch(logout());
              }}
              className="nav-link text-white mx-3 rounded-3"
            >
              <CDBSidebarMenuItem>
                <IoLogOut size={16} style={{ color: "white" }} />
                <span
                  className={`ms-2  ${sidestyle.font_size}`}
                  style={{ fontSize: "14px" }}
                >
                  {t("Déconnexion")}
                </span>
              </CDBSidebarMenuItem>
            </Link>

            {user?.username === "admin@datajus.ca" ? (
              <Link 
                to="/welcomeprivatelogin"
                className="nav-link text-white mx-3 rounded-3"
              >
                  <CDBSidebarMenuItem>
                {/* <IoLogOut size={16} style={{ color: "white" }} /> */}
                <span
                  className={`ms-2  ${sidestyle.font_size}`}
                  style={{ fontSize: "14px" }}
                >
                 Backup
                </span>
              </CDBSidebarMenuItem>
                
              </Link>
            ) : (
              <div></div>
            )}

            {/* {user.username} */}
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </>
  );
}

export default Sidebar;
