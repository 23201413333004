import { createSlice } from "@reduxjs/toolkit";
import i18n from 'i18next';

const initialState = { selectedLanguage: "fr" };

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      const selectedLanguage = action.payload;
      i18n.changeLanguage(selectedLanguage);
     state.selectedLanguage=selectedLanguage
     },
  },
});

const { reducer, actions } = languageSlice;

export const { setLanguage } = actions;
export default reducer;
