const MyAgendaEvent = ({ event }) => (


    <div className="d-flex  ">
        <div style={{
            flex: '1', borderRight: "1px solid lightgrey", height: "7vh"
        }}>
            <p className="ms-2 mt-2 "> {event.title}</p>
        </div>
        <div className="ms-3" style={{ flex: '2' }}>
            <p className="ms-2 mt-2 "> {event.name} </p>
        </div>
    </div>
);
 
export { MyAgendaEvent }


