import React, { useEffect } from "react";
import { newdateformat } from "../../../../../utils";
import { clientsLabel } from "../../components/newcustomer/labels";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, FormControl, InputGroup, Pagination } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import clientService from "../../../../../services/clientservice";
import Loader from "../../../../../utils/Loader";
import { Form } from "react-bootstrap";
 import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function VieWClient() {
  const { t } = useTranslation("global");
 
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [allclientlist, setAllClientList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 100;
  const [total, setTotalcount] = React.useState(0);
  const [sortedBy, setSortedBy] = React.useState("");

  React.useEffect(() => {
    setLoader(true);
    clientService
      .getClientinfobyuser({
        page: currentPage,
        pageSize: pageSize,
        searchBy: searchKeyword ? searchKeyword : "",
        orderByField: sortedBy,
      })
      .then((client) => {
        setAllClientList(client?.clients);
        setTotalcount(client?.totalPages);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  }, [currentPage, pageSize, searchKeyword, sortedBy]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(total, startPage + 4);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (startPage > 1) {
      items.unshift(
        <Pagination.Ellipsis
          className="bg-primary"
          key="ellipsis-start"
          disabled
        />
      );
      items.unshift(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );
    }

    if (endPage < total) {
      items.push(<Pagination.Ellipsis key="ellipsis-end" disabled />);
      items.push(
        <Pagination.Item key={total} onClick={() => handlePageChange(total)}>
          {total}
        </Pagination.Item>
      );
    }

    return items;
  };
  const onSearchClient = (search) => {
    if (search.length > 3) {
      setSearchKeyword(search);
    } else {
      setSearchKeyword("");
    }
  };
  const onSortClient = (e) => {
    setSortedBy(e);
  };

// useEffect(()=>{
//   i18n.changeLanguage(selectedLanguage);

// },[selectedLanguage])
  //  console.log("selectedLanguage",selectedLanguage)
  return (
    <div>
      <>
        <Row>
          <div className="d-flex justify-content-between mb-3">
            <Col md={4}>
              <FormControl
                type="text"
                placeholder={t("Recherche")}
                onChange={(e) => onSearchClient(e.target.value)}
              />
            </Col>
             <Col md={3}>
              <Form.Control
                as="select"
                onChange={(e) => onSortClient(e.target.value)}
              >
                <option value="">{t("Organiser par")}</option>
                <option value="full_name">{t("Nom")}</option>
                <option value="business_registration_date">{t("Date")}</option>
              </Form.Control>
            </Col>
          </div>
        </Row>

        <Table striped>
          <thead>
            <tr>
              <th>{t("client_id")}</th>
              <th>{t("client_name")}</th>
              <th>{t("E-mail")} </th>
              <th>{t("contact_number")}</th>
              <th>{t("date_of_arrival")}</th>
            </tr>
          </thead>
          <tbody>
            {allclientlist?.length ? (
              allclientlist?.map((emp, idx) => (
                <tr
                  key={idx}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/viewClient/${emp.client_id}`)}
                >
                  <td>{emp?.client_code}</td>
                  <td>
                    <span className="text-capitalize">
                      {`${emp?.surname ? emp?.surname : ""} `}
                    </span>
                    <span>{emp?.client_name ? emp?.client_name : ""}</span>
                  </td>
                  <td>{emp?.email || "-"} </td>
                  <td>{emp?.telephone || "-"}</td>
                  <td>
                    {" "}
                    {emp?.business_registration_date
                      ? newdateformat(emp?.business_registration_date)
                      : "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  {loader ? <Loader /> : "Aucun client trouvé"}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
      <Pagination>{renderPaginationItems()}</Pagination>
    </div>
  );
}

export default VieWClient;
