import React, { useEffect, useState } from "react";
import clientService from "../services/clientservice";
import { useTranslation } from "react-i18next";

function AutocompleteClientDropDown({
  storeClientKeyHandler,
  patchClientId,
  onChangeinput = () => {},
  clearflag,
}) {
  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { t } = useTranslation("global");
  const handleInputChange = async (event) => {
    const value = event.target.value;
    onChangeinput(value);
    setSearch(value);

    if (value != "") {
      try {
        const response = await clientService.getAutoCompleteClientsearch({
          searchBy: event.target.value,
          client_id: patchClientId || null,
        });
        if (response?.rows?.length == 0) {
          setSuggestions(search);
        }
        setSuggestions(response?.rows);
      } catch (error) {
        console.error("Error fetching autocomplete suggestions:", error);
      }
    } else {
      setSearch("");
      setSuggestions([]);
    }
  };
  const setSearchKeyhandler = (suggestion) => {
    setSearch(suggestion.surname + " " + suggestion?.client_name);
    storeClientKeyHandler(suggestion);
    setSuggestions([]);
  };
  const patchclientKeyHandler = async () => {
    const response = await clientService.getAutoCompleteClientsearch({
      client_id: patchClientId || null,
    });

    setSearch(
      response?.rows[0]?.surname + " " + response?.rows[0]?.client_name
    );
  };

  useEffect(() => {
    if (clearflag) {
      setSearch("");
    }
  }, [clearflag]);

  useEffect(() => {
    if (patchClientId != null) {
      patchclientKeyHandler();
    }
  }, [patchClientId]);
   return (
    <div>
      <input
        className="form-control"
        type="text"
        value={search}
        onChange={handleInputChange}
        placeholder={t("Rechercher des clients...")}
      />
      <ul
        className="list-group"
        style={{ overflowY: "scroll", maxHeight: "20vh" }}
      >
        {Array.isArray(suggestions) && suggestions.length > 0
          ? suggestions.map((suggestion, index) => (
              <li
                className="list-group-item"
                onClick={() => setSearchKeyhandler(suggestion)}
                key={index}
              >
                {suggestion?.surname && suggestion?.client_name
                  ? `${suggestion.surname} ${suggestion.client_name}`
                  : ""}
              </li>
            ))
          : ""}
      </ul>
    </div>
  );
}

export default AutocompleteClientDropDown;
