import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
 import 'bootstrap/dist/css/bootstrap.min.css';
import Root from './Root';
import "react-datepicker/dist/react-datepicker.css";
import { I18nextProvider } from "react-i18next";
import i18next from 'i18next';
import fr from './screens/admin/screens/components/newcustomer/components/fr/global.json'
import en from './screens/admin/screens/components/newcustomer/components/en/global.json'

i18next.init({
    interpolation: { escapeValue: false },
    lng: "fr ",
    fallbackLng: 'fr',
    resources: {
      en: {
          global:en,
      },
      fr: {
        global:fr,
      },
    },
  });
  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
      <Root /> 
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById("root")
   
);
 
