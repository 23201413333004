import instance from "../axiosConfig";
 
const getReports = ( data) => {
    return instance
      .post(`/report` ,data)
      .then((response) => {
        if (response.status) {
          return response.data;
        } else {
          return response?.data?.error;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getImmigrationslist = ( data) => {
    return instance
      .post(`/report/immigrations` ,data)
      .then((response) => {
        if (response.status) {
          return response.data;
        } else {
          return response?.data?.error;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
 

  

  const getIntervenerReport = ( data) => {
    return instance
      .post(`/report/interver` ,data)
      .then((response) => {
        if (response.status) {
          return response.data;
        } else {
          return response?.data?.error;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getEmployeeReport = ( data) => {
    return instance
      .post(`/report/employee` ,data)
      .then((response) => {
        if (response.status) {
          return response.data;
        } else {
          return response?.data?.error;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sentmessage =(data)=>{
    return instance
      .post(`/email/message` ,data)
      .then((response) => {
        if (response.status) {
          return response.data;
        } else {
          return response?.data?.error;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    
  }
  const sentcontactmessage=(data)=>{
    return instance.post("/email/sendcontact",data)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  }
  export default {sentcontactmessage,getReports ,getImmigrationslist ,getIntervenerReport,getEmployeeReport ,sentmessage}