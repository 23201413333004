import moment from "moment";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { eventMeetingperson } from "./calendarutils";
import { useTranslation } from "react-i18next";

 // blue color appointment pop
export const AppointmentPopup = ({
  event,
  empemail,
  handleDeleteEvent,
  handleClose,
  show,
  showDelete
}) => {
  const { t } = useTranslation("global");

    {/* // blue color appointment pop */}
  return (
    <>
    {/* // blue color appointment pop */}
      <Modal show={show} onHide={handleClose}>
         <Modal.Header closeButton>
          <h5>{event?.title}</h5>
        </Modal.Header>
        <Modal.Body className="mt-3">
          <div className="mx-3">
            <Row className="mb-2">
              <Col md={6}>
                <h6 className="text-secondary">{t("Date et heure")}</h6>
              </Col>
              <Col md={6}>
                <p> 
                  {moment.unix(event?.start_date_and_time).format("D MMMM YYYY")}
                  <span className="ms-3">
                    {event?.start.toLocaleString("en-US", {
                      hour12: false,
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </span>
                  <span > - </span>

                  <span className="">
                    {event?.end.toLocaleString("en-US", {
                      hour12: false,
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </span>
                </p>
              </Col>
            </Row>
            {/* // blue color appointment pop */}
            {event?.organizer_employee_id && (
              <Row className="mb-2">
                <Col md={6}>
                  <h6 className="text-secondary">{t("Organizer name")}</h6>
                </Col>
                <Col md={6}>
                  <p className="text-dark">
                    {
                      empemail?.filter(
                        (x) => x.value === event?.organizer_employee_id
                      )[0]?.label
                    }
                  </p>
                </Col>
              </Row>
            )}
   {/* // blue color appointment pop */}
            {/* {event?.employee_email && event?.email && (
              <Row className="mb-2">
                <Col md={6}>
                  <h6 className="text-secondary"> Courriel </h6>
                </Col>
                <Col md={6}>
                  <p className="text-dark"> {event?.email}</p>
                </Col>
              </Row>
            )} */}

            {  event?.clientName && (
              <Row className="mb-2">
                <Col md={6}>
                  <h6 className="text-secondary">{t("Client")}</h6>
                </Col>
                <Col md={6}>
                  <p className="text-dark">{event?.clientName}</p>
                </Col>
              </Row>
            )}
            {event?.client_email && (
              <Row className="mb-2">
                <Col md={6}>
                  <h6 className="text-secondary">{t("Courriel")}</h6>
                </Col>
                <Col md={6}>
                  <p className="text-dark">{event?.client_email}</p>
                </Col>
              </Row>
            )}
            <Row className="mb-2">
              <Col md={6}>
                <h6 className="text-secondary"> {t("Meeting place")}</h6>
              </Col>
              <Col md={6}>
                <p className="text-dark">{event?.description}</p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <h6 className="text-secondary"> {t("Meeting mode")} </h6>
              </Col>
              <Col md={6}>
                {" "}
                <p className="text-dark">
                  {event?.meeting_mode === 1 ? "En ligne" : "En personne"}
                </p>
              </Col>
            </Row>
            {event?.organizer && (
              <Row className="mb-2">
                <Col md={6}>
                  <h6 className="text-secondary">
                    {event?.type == "EMPLOYEE"
                      ? t("organizer")
                      : t("Organisatrice")}
                  </h6>
                </Col>
                <Col md={6}>
                  <p className="text-dark">
                    {
                      empemail?.filter((x) => x.value === event?.organizer)[0]
                        ?.label
                    }
                  </p>
                </Col>
              </Row>
            )}
            {/* {event?.email && (
              <Row className="mb-2">
                <Col md={6}>
                  <h6 className="text-secondary">
                    {" "}
                    Personne à l'entretien E-mail
                  </h6>
                </Col>
                <Col md={6}>
                  {" "}
                  <p className="text-dark">
                    {event?.email}
                  </p>
                </Col>
              </Row>
            )} */}
            {event?.meeting_person && (
              <Row className="mb-2">
                <Col md={6}>
                  <h6 className="text-secondary"> {t("No one at the meeting")}</h6>
                </Col>
                <Col md={6}>
                  {" "}
                  <p className="text-dark">
                    {eventMeetingperson(event?.meeting_person, empemail)}

                  </p>
                 
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
       <hr />
        {/* // blue color appointment pop */}
        {showDelete ? (
          
            <div className="d-flex justify-content-between pb-3">
            <a style={{"backgroundColor":"#585A96",color:"white"}} target="_blank" className="text-decoration-none btn btn-sm btn- ms-3 mt-1" href="https://teams.live.com/meet/9565768211001?p=zQH9vDdE7KuHngRo">{t("Joignez-vous à la réunion")}</a>
            <Button variant="danger" className="me-3" onClick={() => handleDeleteEvent(event?.id)}>
              <MdDelete className="mb-1 " />
            </Button>
            </div> 
        ) : (
          ""
        )}
      </Modal>
    
    </>
  )

};
