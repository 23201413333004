import React from "react";
import useSettings from "../../../../../hooks";
import Form from "react-bootstrap/Form";
import CreatableSelect from "react-select/creatable";
import Button from "react-bootstrap/Button";
import clientService from "../../../../../services/clientservice";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { intervenanteLabel } from "../../components/newcustomer/labels";
import AutocompleteClientDropDown from "../../../../../hooks/useClientList";
import { frequency_services } from "../../components/newcustomer/components/constant";
import { extraValues } from "../utils";
import { useTranslation } from "react-i18next";
registerLocale("fr", fr);

function AddIntervene({ addcustomer, setAddCustomer, id, interveneclient }) {
  const { inter_id } = useParams();
  const { getPartners, intervenerservice, nom, empemail } = useSettings();
  const {
    register,
    reset,
    setValue,
    control,
    watch,
    handleSubmit,
    setError,
    formState,
  } = useForm();
  const [clientlist, setClientList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [clientIdData, setClientIdData] = React.useState(null);
  const [patchclientId, setPatchClientId] = React.useState(null);

  const extraSelectValues = async (extra_select, type) => {
    if (extra_select) {
      const existData = extra_select.label != extra_select.value;

      if (extra_select?.__isNew__) {
        const payload = {
          name: extra_select.label,
          type: type,
          entry: "MANUAL",
        };
        const insertpayload = await clientService.insertSetting(payload);
        return insertpayload.worker_id;
      }

      if (existData) {
        return extra_select?.value;
      }
    }
  };

  const clientChangehandler = (e) => {
    setValue("client", e);

    if (e === "") {
      setClientIdData(null);
    }
  };

  const onSubmit = async (data) => {
 
    setLoader(true);
    data.client_id = clientIdData?.client_id || patchclientId || id;
    const recommended_organizations_data = await extraValues(
      data?.recommended_organization,
      "PARTNER"
    );
    data.recommended_organization = recommended_organizations_data;

    const services_offers_data = await extraValues(
      data?.services_offer,
      "INTERVENANTESERVICE"
    );
    data.services_offer = services_offers_data;

    const names = await extraSelectValues(data?.name, "NOM");
    const empnames = await extraSelectValues(data?.empname, "EMP_EMAIL");

    data.name = names;
    data.empname = empnames;
    data.frequency_service = data?.frequency_service?.value;
    data.intervener_minutes = data?.intervener_minutes;
    data.appointment_date = new Date(data.appointment_date).getTime();

    if (inter_id || interveneclient) {
      clientService
        ?.updateIntervenet(inter_id || interveneclient, data)
        .then((res) => {
          if (res) {
            setLoader(false);
            toast.success("Intervenant mis à jour avec succès");
            setAddCustomer(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(Object.values(error?.msg[0]));
        });
    } else {
      clientService
        ?.addIntervenet(data)
        .then((res) => {
          if (res) {
            setLoader(false);
            toast.success("Intervenant ajouté avec succès");
            setAddCustomer(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(Object.values(error?.msg[0]));
        });
    }
  };
  React.useEffect(() => {
    clientService
      .getClientInfo()
      .then((client) => {
        const clientlist = client?.map((e) => {
          return {
            label: `${e.surname} ${e.client_name}`,
            value: e?.client_id,
          };
        });
        setClientList(clientlist);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  React.useEffect(() => {
    if (inter_id || interveneclient) {
      clientService
        .getIntevinerByInter_Id(inter_id || interveneclient)
        .then((res) => {
          if (res) {
            setPatchClientId(res.client_id || id);

            const nameservices = nom
              ?.filter((serve) => serve?.value == res?.name)
              ?.map((x) => {
                return {
                  label: x?.label,
                  value: x?.label,
                };
              });
            const empnameservices = empemail
              ?.filter((serve) => serve?.value == res?.empname)
              ?.map((x) => {
                return {
                  label: x?.label,
                  value: x?.label,
                };
              });
            setValue("name", nameservices);
            setValue("empname", empnameservices);
            setValue("appointment_date", res?.appointment_date);
            setValue("intervener_minutes", res?.intervener_minutes);

            setValue("frequency_service", {
              label: res?.frequency_service,
              value: res?.frequency_service,
            });
            const services_offer_data = res?.services_offer
              .split(",")
              ?.map((x) => x);

            const services_offer_data_patch = intervenerservice?.filter(
              (data) => services_offer_data?.some((x) => x == data.value)
            );
            setValue("services_offer", services_offer_data_patch);

            setValue("mode_of_intervention", res?.mode_of_intervention);

            const recommended_organization_data = res?.recommended_organization
              .split(",")
              ?.map((x) => x);

            const recommended_organization_data_patch = getPartners?.filter(
              (data) =>
                recommended_organization_data?.some((x) => x == data.value)
            );
            setValue(
              "recommended_organization",
              recommended_organization_data_patch
            );
            console.log(
              "recommended_organization_data_patch",
              recommended_organization_data_patch
            );
            setValue("qualify_MIFI", res?.qualify_MIFI);

            setValue("feedback", res?.feedback);
          }
        });
    }
  }, [inter_id, interveneclient, getPartners, clientlist]);
  React.useEffect(() => {
    if (id && clientlist?.length) {
      setValue("clientId", clientlist.filter((x) => x.value == id)[0]?.label);
    }
  }, [id, clientlist]);

  const storeClientKeyHandler = (client) => {
    setClientIdData(client);
  };
   const { t } = useTranslation("global");

  return (
    <div className="mt-4">
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  Client *
                </Form.Label>
                <AutocompleteClientDropDown
                  storeClientKeyHandler={storeClientKeyHandler}
                  patchClientId={patchclientId || id || null}
                  onChangeinput={(e) => clientChangehandler(e)}
                  inputRef={register("client", {
                    required: t("Ce champ est obligatoire"),
                  })}
                />
                {formState?.errors?.client && (
                  <p className="text-danger">
                    {formState?.errors?.client?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  {t("Date de rendez-vous")} *
                </Form.Label>{" "}
                <br />
                <Controller
                  name="appointment_date"
                  control={control}
                  rules={{ required: t("Ce champ est obligatoire") }}
                  render={({ field }) => (
                    <DatePicker
                      locale="fr"
                      selected={field.value}
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["appointment_date"]) {
                          setError("appointment_date", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      className="form-control placeholder-color"
                      dateFormat="yyyy/MM/dd"
                      placeholderText="AAAA/MM/JJ"
                      wrapperClassName="datePicker"
                    />
                  )}
                />
                {formState.errors.appointment_date && (
                  <p className="text-danger">
                    {formState.errors.appointment_date.message}
                  </p>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  {t("Nom de l'employée")} *
                </Form.Label>

                <Controller
                  name="empname"
                  rules={{ required: t("Ce champ est obligatoire") }}
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      isClearable
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["empname"]) {
                          setError("empname", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      placeholder={t("Sélectionner")}
                      {...field}
                      options={empemail}
                    />
                  )}
                />
                {formState.errors.empname && (
                  <p className="text-danger">
                    {formState.errors.empname.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="firstname">
                <Form.Label className="text-secondary fw-bold">
                  {" "}
                  {t("Services offerts")}
                </Form.Label>

                <Controller
                  name="services_offer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isMulti
                      isClearable
                      onChange={(date) => {
                        field.onChange(date);
                        // if (formState.errors["services_offer"]) {
                        //   setError("services_offer", {
                        //     type: "manual",
                        //     message: "",
                        //   });
                        // }
                      }}
                      // formatCreateLabel={(inputValue) =>
                      //   `Créer "${inputValue}"`
                      // }
                      options={intervenerservice}
                      placeholder={t("Sélectionner")}
                    />
                  )}
                />
                {/* {formState.errors.services_offer && (
                  <p className="text-danger">
                    {formState.errors.services_offer.message}
                  </p>
                )} */}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                  {t("Mode d’intervention")} *
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register("mode_of_intervention", {
                    required: t("Ce champ est obligatoire"),
                  })}
                >
                  <option value="">{t("Sélectionner")}</option>
                  <option value="En personne">En personne</option>
                  <option value="En ligne">En ligne</option>
                </Form.Select>
                {formState?.errors?.mode_of_intervention && (
                  <p className="text-danger">
                    {formState?.errors?.mode_of_intervention?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="lastname">
                <Form.Label className="text-secondary fw-bold">
                  {t("Référé")} *
                </Form.Label>
                <Controller
                  name="recommended_organization"
                  control={control}
                  rules={{ required: t("Ce champ est obligatoire") }}
                  render={({ field }) => (
                    <CreatableSelect
                      isMulti
                      onChange={(date) => {
                        field.onChange(date);
                        if (formState.errors["recommended_organization"]) {
                          setError("recommended_organization", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                      {...field}
                      options={getPartners}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      placeholder={t("Sélectionner")}
                    />
                  )}
                />
                {formState.errors.recommended_organization && (
                  <p className="text-danger">
                    {formState.errors.recommended_organization.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="Role">
                <Form.Label className="text-secondary fw-bold">
                   {t("Se qualifie pour le MIFI")} *
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  {...register("qualify_MIFI", {
                    required: t("Ce champ est obligatoire"),
                  })}
                >
                  <option value="">{t("Sélectionner")}</option>
                  <option value="Oui">{t("Oui")}</option>
                  <option value="Non">{t("Non")}</option>
                </Form.Select>
                {formState?.errors?.qualify_MIFI && (
                  <p className="text-danger">
                    {formState?.errors?.qualify_MIFI?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="lastname">
                    <Form.Label className="text-secondary fw-bold">
                      {t("La durée d'intervention (en minutes)")} *
                    </Form.Label>
                    <Form.Control
                      type="number"
                      {...register("intervener_minutes", {
                        required: t("Ce champ est obligatoire"),
                      })}
                    />
                    {formState?.errors?.intervener_minutes && (
                      <p className="text-danger">
                        {formState?.errors?.intervener_minutes?.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label className="text-secondary fw-bold">
                      {t("Fréquence du service")} *
                    </Form.Label>
                    <Controller
                      name="frequency_service"
                      control={control}
                      rules={{ required: t("Ce champ est obligatoire") }}
                      render={({ field }) => (
                        <CreatableSelect
                          placeholder={t("Sélectionner")}
                          {...field}
                          onChange={(date) => {
                            field.onChange(date);
                            if (formState.errors["frequency_service"]) {
                              setError("frequency_service", {
                                type: "manual",
                                message: "",
                              });
                            }
                          }}
                          formatCreateLabel={(inputValue) =>
                            `Créer "${inputValue}"`
                          }
                          isClearable
                          options={frequency_services}
                        />
                      )}
                    />
                    {formState.errors.frequency_service && (
                      <p className="text-danger">
                        {formState.errors.frequency_service.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="lastname">
                <Form.Label className="text-secondary fw-bold">
                  {t("Commentaires")} *
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  {...register("feedback", {
                    required: t("Ce champ est obligatoire"),
                  })}
                />
                {formState?.errors?.feedback && (
                  <p className="text-danger">
                    {formState?.errors?.feedback?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="col-md-3">
            <button
              className="btn btn-primary btn-sm"
              type="submit"
              style={{ width: "120px" }}
              disabled={loader}
            >
              {loader && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              {t(inter_id || interveneclient ? "Mise à jour" : "Sauvegarder")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddIntervene;
