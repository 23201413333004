import instance from "../axiosConfig";

const getClientInfo = () => {
  return instance
    .get(`/client`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getDashboardInfo = () => {
  return instance
    .get(`/dashboard`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getIntervenet = () => {
  return instance
    .get(`/intervient`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};


const getAllcontact = () => {
  return instance
    .get(`/contact`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const addContact = (data) => {
  return instance
    .post(`/contact`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
    });
};

const addContactlist = (data) => {
  return instance
    .post(`/addcontactlist`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
    });
};

const filterContactByCategory = (data) => {
  const payload = {
    category:data
  }
  return instance
  .post(`/contact/filtercategory`, payload)
    .then((response) => {
      if (response.status) {
        return response.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
    });
};


const addToClient = (data) => {
  const payload = {
    id:data
  }
  return instance
    .post(`/contact/addtoclient`, payload)
    .then((response) => {
      if (response.status) {
        return response.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
    });
};

const getAutoCompleteClientsearch=({searchBy,client_id})=>{

return instance
    .get(`/clientsearch`, {
      params: {
        searchBy:searchBy, 
        client_id:client_id
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return { error: response?.data?.error || 'Unknown error occurred' };
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      return { error: 'An error occurred while fetching intervenet data.' };
    });
};

const getClientinfobyuser = ({ page, pageSize ,searchBy,orderByField}) => {
   return instance
    .get(`/client/clientbyuser/`, {
      params: {
        page: page,
        pageSize: pageSize,
        searchBy:searchBy,
        orderByField
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return { error: response?.data?.error || 'Unknown error occurred' };
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      return { error: 'An error occurred while fetching intervenet data.' };
    });
};

const getActivity = () => {
  return instance
    .get(`/activity`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getAllsettings = () => {
  return instance
    .get(`/setting`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const insertSetting = (payload) => {
  return instance
    .post(`/setting`, payload)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const addClientInfo = (data) => {
  return instance
    .post(`/client`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
    });
};
const addAdmini = (data) => {
  return instance
    .post(`/administration`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
    });
};
const addIntervenet = (data) => {
  return instance
    .post(`/intervient`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
    });
};
const addActivity = (data) => {
  return instance
    .post(`/activity`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
    });
};

const getAllAdmini = (data) => {
  return instance
    .get(`/administration`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getClientInfoById = (id) => {
  return instance
    .get(`/client/single/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getContactById = (id) => {
  return instance
    .get(`/contact/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const getIntevinerById = (id) => {
  return instance
    .get(`/intervient/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getClientById = (id) => {
  return instance
    .get(`/client/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const getIntevinerByInter_Id = (id) => {
  return instance
    .get(`/intervient/inter_id/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const getActivityById = (id) => {
  return instance
    .get(`/activity/activity_id/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const getActivityByclientId = (id) => {
  return instance
    .get(`/activity/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const getAdminiById = (id) => {
  return instance
    .get(`/administration/admin_id/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getAdmininfo = (id) => {
  return instance
    .get(`/administration/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const updateIntervenet = (id, data) => {
  return instance
    .put(`/intervient/${id}`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const updateContact = (id, data) => {
  return instance
    .put(`/contact/${id}`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};


const deleteContact = (id) => {
  return instance
    .delete(`/contact/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const updateActivity = (id, data) => {
  return instance
    .put(`/activity/${id}`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const updateAdministration = (id, data) => {
  return instance
    .put(`/administration/${id}`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};



const updateClientInfo = (id, data) => {
  return instance
    .put(`/client/${id}`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const clientService = {
  insertSetting,
  addAdmini,
  getAdminiById,
  getAllsettings,
  getIntevinerByInter_Id,
  getAllAdmini,
  getIntervenet,
  addIntervenet,
  getClientById,
  getClientInfo,
  getClientInfoById,
  addClientInfo,
  updateClientInfo,
  getIntevinerById,
  getAdmininfo,
  updateIntervenet,
  updateAdministration,
  getDashboardInfo,
  getActivity,
  addActivity,
  getActivityById,updateActivity,getActivityByclientId,getClientinfobyuser,
  getAllcontact,
addContact,
getContactById,
updateContact,addToClient,addContactlist ,deleteContact,getAutoCompleteClientsearch,filterContactByCategory
};
export default clientService;
